import createPersistedState from 'vuex-persistedstate';
import { Context } from '@nuxt/types';

export default ({ store, res }: any) => {
  
  if (process.client) {
    // let headersObj: any = {};

    // if ((res as any).headers.hasOwnProperty('Eagleid')) {
    //   headersObj['Eagleid'] = (res as any).headers.Eagleid;
    //   headersObj['Tlogtraceid'] = (res as any).headers.Tlogtraceid;
    //   headersObj['Nuxttraceid'] = (res as any).headers.Nuxttraceid;
    // }
    // store.commit('setHeaders', headersObj); // 记录页面响应头
    // if (route.name !== '首页' && route.name !== '展会列表') {
    window.onNuxtReady(() => {
      createPersistedState({
        paths: [
          'global.user',
          'global.openId',
          'global.isWebp',
          'user',
          'order',
          'visitor',
          'i18n',
        ],
        key: 'store', // 读取本地存储的数据到store
      })(store);
    });
    // }
  }
};
