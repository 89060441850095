import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
  name: 'airTicketOrder',
  stateFactory: true,
  namespaced: true,
})
export default class AirTicket extends VuexModule {
  searchInfo = {}; // 搜索条件(出发地,目的地,出发日期,返程日期...)
  searchTime = 0; // 机票列表搜索时间(超出20分钟后则需要重新查询)
  flightInfo = {}; // 航班信息,机票订单表单信息
  cabinInfo = {}; // 舱位信息,
  goFlightInfo = {}; // 往返-已选去程-航班信息
  goCabinInfo = {}; // 往返-已选去程-舱位信息
  travelerInfoList = []; // 乘机人

  @Mutation
  setSearchInfo(data: any) {
    this.searchInfo = data;
  }

  @Mutation
  setSearchTime(data: any) {
    this.searchTime = data;
  }

  @Mutation
  setFlightInfo(data: any) {
    this.flightInfo = data;
  }

  @Mutation
  setCabinInfo(data: any) {
    this.cabinInfo = data;
  }

  @Mutation
  setGoFlightInfo(data: any) {
    this.goFlightInfo = data;
  }

  @Mutation
  setGoCabinInfo(data: any) {
    this.goFlightInfo = data;
  }

  @Mutation
  setTravelerInfo(data: any) {
    this.travelerInfoList = data;
  }
}
