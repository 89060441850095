import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
  name: 'user',
  stateFactory: true,
  namespaced: true,
})
export default class User extends VuexModule {
  origins = ''; // 来源页面

  action = 0; // 来源接口   1:展位预订  2:门票预订  3:展商名录  4:开展提醒

  industryId = 0; // 展会行业

  bdUrl = '';

  @Mutation
  setOrigins(data: any) {
    this.origins = data;
  }

  @Mutation
  setAction(data: any) {
    this.action = data;
  }

  @Mutation
  setIndustryId(data: any) {
    this.industryId = data;
  }

  @Mutation
  setBDurl(data: any) {
    this.bdUrl = data;
  }
}
