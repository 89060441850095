import createPersistedState from 'vuex-persistedstate';
import { Context } from '@nuxt/types';

export default ({ app: { context, router }, nuxtState }: Context) => {
  // context.insertHtml = (html: any) => {
  //   console.log('plugins-ssr6', context.res.html);
  //   const bodyTag = context.res.html.match(/<body[^>]*>([\s\S]*?)<\/body>/);
  //   console.log('plugins-ssr8', bodyTag);
  //   if (bodyTag) {
  //     context.res.html = context.res.html.replace(
  //       bodyTag[0],
  //       `${bodyTag[0]}\n<div>100-100-100${html}</div>`
  //     );
  //   }
  // };
  // console.log('plugins-ssr', context.res);
  // 服务端渲染完成后的逻辑
  // router?.afterEach((to, from) => {
  //   console.log('plugins-ssr', to, from);
  //   if (to.path.includes('/seo/')) {
  //     // nuxtState.data = null;
  //   }
  //   console.log('plugins-ssr-21', nuxtState);
  // });
};
