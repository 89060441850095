/**
 * i18n
 * @author wwl
 * @created 2023/12/28
 * @modified 2023/12/28
 */
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
  name: 'i18n',
  stateFactory: true,
  namespaced: true,
})
export default class I18n extends VuexModule {
  locales = ['en', 'zh']

  locale = 'zh'

  @Mutation
  SET_LANG(locale: string) {
    if (this.locales.includes(locale)) {
      this.locale = locale
    }
  }
}
