import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
  name: 'order',
  stateFactory: true,
  namespaced: true,
})
export default class Order extends VuexModule {
  periodical = {}; // 期刊订单表单信息

  @Mutation
  setPeriodical(data: any) {
    this.periodical = data;
  }
}
