// 0:扫码登录, 1:验证码登录, 2:密码登录
export enum Tabs {
  wechat = 0,
  code = 1,
  pwd = 2,
}

// 0:登录, 1:绑定账号, 2:忘记密码, 3:立即注册
export enum FormType {
  login = 0,
  bind = 1,
  forget = 2,
  register = 3,
}
