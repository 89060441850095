// declare module 'lru-cache';
const LRU = require('lru-cache');
// import LRUCache from 'lru-cache';

const cache: any = new LRU({
  max: 2000, // 缓存最大条目数
  maxAge: 1000 * 3600 * 24, // 缓存时间600秒 单位：毫秒 3600秒=1小时 *24 表示24小时
});

export default (ctx: any, inject: any) => {
  // 注入cache到Nuxt上下文
  inject('cache', cache);
};
