import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import Global from '~/store/global';
import Order from '~/store/order';
import User from '~/store/user';
import Visitor from '~/store/visitor';
import AirTicket from '~/store/airTicket';
import I18n from '~/store/i18n';

// eslint-disable-next-line import/no-mutable-exports
let global: Global;
// eslint-disable-next-line import/no-mutable-exports
let order: Order;
// eslint-disable-next-line import/no-mutable-exports
let user: User;
// eslint-disable-next-line import/no-mutable-exports
let visitor: Visitor;
// eslint-disable-next-line import/no-mutable-exports
let airTicketOrder: AirTicket;
// eslint-disable-next-line import/no-mutable-exports
let i18n: I18n;

function initialiseStores(store: Store<any>): void {
  global = getModule(Global, store);
  order = getModule(Order, store);
  user = getModule(User, store);
  visitor = getModule(Visitor, store);
  airTicketOrder = getModule(AirTicket, store);
  i18n = getModule(I18n, store);
}

export { initialiseStores, global, order, user, visitor, airTicketOrder, i18n };
