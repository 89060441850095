/**
 * monitor.client.ts
 * @author wwl
 * @created 2023/11/22
 * @modified 2023/11/22
 */
import Vue from 'vue'

export default function () {
  Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
    if (process.client) {
      const fundebug = require('fundebug-javascript');
      fundebug.notify("ErrorCaptured", info, {
        metaData: {err, vm, info}
      });
    } else {
      console.error('errorHandler', err);
    }
  }

  Vue.prototype.$throw = (err: Error, vm: Vue, info: string) => {
    if (process.client) {
      const fundebug = require('fundebug-javascript');
      fundebug.notify("ErrorCaptured", info, {
        metaData: {err, vm, info}
      });
    } else {
      console.error('errorHandler', err);
    }
  };

  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    if (process.client) {
      const fundebug = require('fundebug-javascript');
      fundebug.notify("ErrorCaptured", event.reason, {
        metaData: {event}
      });
    } else {
      console.error('onunhandledrejection', event);
    }
  }
}
