/**
 * i18n
 * @author wwl
 * @created 2023/11/6
 * @modified 2023/11/6
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Context } from '@nuxt/types';
import en_us from '../lang/en_us';
import zh_cn from '../lang/zh_cn';

Vue.use(VueI18n);

const messages = {
  en: en_us, // 导入英文语言包
  zh: zh_cn, // 导入中文语言包
};

export default ({ app, store }: Context) => {
  // console.log('store plugin >>>', store.state.i18n.locale)

  app.i18n = new VueI18n({
    locale: store.state.i18n.locale,
    fallbackLocale: 'zh',
    messages,
  });
};
