import { Plugin } from '@nuxt/types';

declare global {
  interface Window {
    openWithSuffix(url: string, target?: string): Window | null;
  }
}

const windowOpenPlugin: Plugin = (context, inject) => {
  let resultUrl = '';
  let bdurl = window.location.href;
  let startIndex = bdurl.indexOf('#bd'); // 获取 "#bd" 后面的第一个字符的索引
  let startIndex1 = bdurl.indexOf('#sx360'); // 获取 "#sx360" 后面的第一个字符的索引
  if (startIndex !== -1) {
    resultUrl = bdurl.substring(startIndex); // 截取从 startIndex 开始到字符串的末尾的部分
  }
  if (startIndex1 !== -1) {
    resultUrl = bdurl.substring(startIndex1); // 截取从 startIndex 开始到字符串的末尾的部分
  }
  let modifiedUrl = '';
  window.openWithSuffix = function (url: string, target: string) {
    // 添加后缀
    if (resultUrl) {
      modifiedUrl = url + resultUrl;
    } else {
      modifiedUrl = url;
    }
    return window.open(modifiedUrl, target);
  };

  inject('windowOpen', window.openWithSuffix);
};

export default windowOpenPlugin;
