export enum ResponseCode {
  SUCCESS = '1',
  ERROR = '0', // 服务器异常
  ILLEGAL_REFERENCE = '101',
  UNREGISTERED = '20000',
  AUTH_FAILED = '401',
  NO_LOGIN = '111006',
  BLACK_LOGIN = '111008',
  RESTART_LOGIN = '200016',
  TOKEN_NULL = '20001',
  ILLEGA_PARAMETER = '20002',
  DUPLICATED_USER = '20003',
  USER_DONT_EXIST = '20004',
  CLASS_NAME_ALREADY_EXIST = '20005',
  CLASS_NOT_EXIST = '20006',
  COURSE_DONT_EXIST = '20007',
  ERROR_CODE = '20008',
  WORKS_DONT_EXIST = '20009',
  NOT_HEADMASTER = '20010',
  NOT_MASTER = '20011',
  UP_FAILURE = '20012',
  PHONE_ALREADY_BIND = '20013',
  USER_ALREADY_SIGNED = '200014',
  NO_CODE_SCANNING = '200015',
  NUMBER_999 = '999',
}
