import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
  name: 'ticket',
  stateFactory: true,
  namespaced: true,
})
export default class ticket extends VuexModule {
  ticketList = []; // 购票人员信息

  @Mutation
  setTicketInfo(data: any) {
    this.ticketList = data;
  }
}
