import { Context } from '@nuxt/types/app';
// import Vue from "vue";
import { $cookies } from '~/util/api';
import { getMidnightTimestamp } from '~/util/common';

export default (ctx: Context) => {
  const { app } = ctx;
  (app.router as any).beforeEach((to: any, _from: any, next: any) => {
    // 路由拦截
    const token = $cookies.get('access_token');

    // if (process.client) {
    //   let pageViewStr = localStorage.getItem('pageView'); // 统计页面访问量
    //   console.log('10-页面访问数量', pageViewStr, typeof pageViewStr);
    //   console.log('15-', to, _from);
    //   if (pageViewStr) {
    //     let pageView = JSON.parse(pageViewStr);
    //     if (getMidnightTimestamp() > pageView.expirationTime) {
    //       pageView.expirationTime = getMidnightTimestamp();
    //       pageView.total = 1;
    //     }
    //     // 如果当前页面访问次数大于5次，且未登录，且不在登录页, 则跳转登录页
    //     if (Number(pageView.total) >= 5 && !token && to.path !== '/login/') {
    //       console.log('5次访问-去登录');
    //       // 未登录,跳转到登陆页面
    //       next({
    //         path: `/login/?redirect=${encodeURIComponent(to.fullPath)}`,
    //       });
    //     } else {
    //       console.log('正常访问');
    //       pageView.total = Number(pageView.total) + 1;
    //       localStorage.setItem('pageView', JSON.stringify(pageView));
    //       next();
    //     }
    //   } else {
    //     let pageViewVal = { total: 1, expirationTime: getMidnightTimestamp() };
    //     localStorage.setItem('pageView', JSON.stringify(pageViewVal));
    //     next();
    //   }
    // }

    ctx.store.commit('global/setIsLogin', !!token);
    const { isLogin, user } = ctx.store.state.global;
    if (process.client && isLogin) {
      if (!user.isPererffect) {
        // Vue.prototype.$completeInfo.show()
      }
    }
    // 判断该路由是否需要登录权限
    if (to.meta.isLogin) {
      if (!token) {
        // 未登录,跳转到登陆页面
        next({
          path: `/login/?redirect=${encodeURIComponent(to.fullPath)}`,
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
  app.router?.onError((error) => {
    try {
      const pattern = /Loading chunk (\d)+ failed/g;
      const isChunkLoadFailed = error.message.match(pattern);
      const targetPath = (app.router as any).history.pending.fullPath;
      if (isChunkLoadFailed) {
        app.router?.replace(targetPath);
      }
    } catch (error) {
      // if (process.client) {
      //   const fundebug = require('fundebug-javascript');
      //   fundebug.notifyError(error);
      // }
      console.error(error);
    }
  });
};
