import { Store } from 'vuex';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { NuxtCookies } from 'cookie-universal-nuxt';
import VueI18n from 'vue-i18n';

// eslint-disable-next-line import/no-mutable-exports
let $axios: NuxtAxiosInstance;

export function initializeAxios(axiosInstance: NuxtAxiosInstance) {
  $axios = axiosInstance;
}

// eslint-disable-next-line import/no-mutable-exports
let $cookies: NuxtCookies;

export function initializeCookies(cookiesInstance: NuxtCookies) {
  $cookies = cookiesInstance;
}

/**
 * 设置cookie
 * name cookie的key
 * value cookie的值
 * days  过期时间 13位时间戳
 * domain  域名
 * */
export function handleSetCookie(
  name: string,
  value: string,
  days: number,
  domain: string = ''
) {
  let domainStr = '';
  if (domain) {
    domainStr = domain;
  } else {
    domainStr = window.location.host.includes('.jufair.com')
      ? 'jufair.com'
      : window.location.hostname;
  }

  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name + '=' + (value || '') + expires + '; domain=' + domainStr + '; path=/';
}

// eslint-disable-next-line import/no-mutable-exports
let $store: Store<any>;

export function initializeStore(storeInstance: Store<any>) {
  $store = storeInstance;
}

// eslint-disable-next-line import/no-mutable-exports
let $i18n: VueI18n;

export function initializeI18n(instance: VueI18n) {
  $i18n = instance;
}

export { $axios, $cookies, $store, $i18n };
